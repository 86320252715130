<template>
  <div class="bg">
    <div class="header">
      <div class="user">
        <div class="avatar">
          <img src="../assets/img/touxiang.png" alt="">
        </div>
        <div class="name">{{userInfo.phoneNum}}</div>
      </div>
    </div>
    <div class="list">
      <div class="item ds_flex" @click="toRoute('/my_focus_on')">
        <div class="icon_wrap">
          <img src="../assets/img/my_gz.png" alt="my_gz" class="my_gz">
        </div>
        <p class="txt">我的关注</p>
        <img src="../assets/img/right_icon.png" alt="" class="icon">
      </div>
      <div class="item ds_flex" @click="toRoute('/consult')">
        <div class="icon_wrap">
          <img src="../assets/img/my_wz.png" alt="my_wz" class="my_wz">
        </div>
        <p class="txt">我的问诊</p>
        <img src="../assets/img/right_icon.png" alt="" class="icon">
      </div>
      <div class="item ds_flex" @click="toRoute('/choose_patients')">
        <div class="icon_wrap">
          <img src="../assets/img/my_icon.png" alt="my_icon" class="my_icon">
        </div>
        <p class="txt">问诊人信息</p>
        <img src="../assets/img/right_icon.png" alt="" class="icon">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: {}
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userData'));
  },
  methods: {
    toRoute(path) {
      if (path === '/choose_patients') {
        this.$router.push({ path: path, query: { isShowBtn: false, showRadio: false } })
      } else {
        this.$router.push(path);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ds_flex {
  display: flex;
  display: -webkitflex;
  align-items: center;
  -webkit-align-items: center;
}
.bg {
  min-height: 100%;
  background: #f8f8f8;
  // padding-bottom: 1.86px;
}
.header {
  padding: 0.34rem 0.24rem;
  // height: 2.9rem;
  width: calc(100% - 0.48rem);
  background: linear-gradient(to bottom, #ffc025, #f8f8f8) no-repeat top / 100%
    1.52rem;
  // background: linear-gradient(
  //   to bottom,
  //   #ffc025 0,
  //   #ffc025 1.52rem,
  //   #f8f8f8 1.52rem
  // );
  // background: -webkit-linear-gradient(
  //   to bottom,
  //   #ffc025 0,
  //   #ffc025 1.52rem,
  //   #f8f8f8 1.52rem
  // );
  .user {
    padding: 0.46rem 0;
    box-shadow: 0px -6px 0.28rem 0px #ffa700;
    border-radius: 0.58rem;
    background: linear-gradient(to right, #ffcb3a, #ffa633);
    background: -webkit-linear-gradient(to right, #ffcb3a, #ffa633);
    .avatar {
      margin: 0 auto 0.26rem;
      width: 1.22rem;
      height: 1.22rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      text-align: center;
      font-size: 0.32rem;
      color: #fff;
    }
  }
}
.list {
  padding: 0 0.38rem 0.28rem;
  background: #fff;
  .item {
    border-bottom: 2px solid#F9F9F9;
    padding-bottom: 0.26rem;
    padding-top: 0.38rem;
    .icon_wrap {
      margin-right: 0.24rem;
      width: 0.36rem;
      .my_gz {
        margin: 0 auto;
        width: 0.36rem;
        height: 0.3rem;
      }
      .my_wz {
        margin: 0 auto;
        width: 0.34rem;
        height: 0.34rem;
      }
      .my_icon {
        margin: 0 auto;
        width: 0.3rem;
        height: 0.32rem;
      }
    }
    .txt {
      flex: 1;
      -webkit-flex: 1;
      font-size: 0.28rem;
      color: #444444;
    }
    .icon {
      width: 0.14rem;
      height: 0.24rem;
    }
  }
  .item:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}
</style>